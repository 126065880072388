import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Text, VStack, HStack, useToast } from '@chakra-ui/react';
import { db, auth } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const formatPhoneNumber = (phone) => {
  let cleanPhone = phone.replace(/[^\d+]/g, '');
  if (cleanPhone.startsWith('+972')) {
    return cleanPhone;
  }
  if (cleanPhone.startsWith('972')) {
    return '+' + cleanPhone;
  }
  if (cleanPhone.startsWith('0')) {
    return '+972' + cleanPhone.slice(1);
  }
  return '+972' + cleanPhone;
};

const displayPhoneNumber = (phone) => {
  if (phone.startsWith('+972')) {
    return '0' + phone.slice(4);
  }
  return phone;
};

const CallSection = ({ selectedContacts }) => {
  const [customerPhone, setCustomerPhone] = useState('');
  const [callCount, setCallCount] = useState('');
  const [callStatus, setCallStatus] = useState('idle');
  const [contacts, setContacts] = useState([]);
  const [callsMade, setCallsMade] = useState({});
  const [answeredContact, setAnsweredContact] = useState(null);
  const [totalCallsMade, setTotalCallsMade] = useState(0);
  const [isCallActive, setIsCallActive] = useState(false);
  const [currentCallIndex, setCurrentCallIndex] = useState(0);
  const toast = useToast();

  useEffect(() => {
    const fetchContacts = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const contactsQuery = query(collection(db, `users/${user.uid}/contacts`));
          const querySnapshot = await getDocs(contactsQuery);
          const contactsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setContacts(contactsList);
        }
      });
    };
    fetchContacts();
  }, []);

  const clearPreviousSessionData = () => {
    setCallStatus('idle');
    setCallsMade({});
    setAnsweredContact(null);
    setTotalCallsMade(0);
    setIsCallActive(false);
    setCurrentCallIndex(0);
  };

  const startCalls = async () => {
    if (selectedContacts.length === 0) {
      toast({
        title: "לא נבחרו אנשי קשר",
        description: "אנא בחר לפחות איש קשר אחד להתקשרות.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!callCount || isNaN(parseInt(callCount, 10)) || parseInt(callCount, 10) <= 0) {
      toast({
        title: "מספר שיחות לא תקין",
        description: "אנא הכנס מספר תקין של ניסיונות שיחה.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!customerPhone) {
      toast({
        title: "חסר מספר טלפון של לקוח",
        description: "אנא הכנס את מספר הטלפון של הלקוח.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    clearPreviousSessionData();
    setCallStatus('מתחיל שיחות');
    setIsCallActive(true);

    try {
      const response = await fetch('https://nexmo-heroku-python-server-7146b95f181d.herokuapp.com/start-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to_number: selectedContacts.map(id => formatPhoneNumber(contacts.find(c => c.id === id).phone)),
          max_attempts: parseInt(callCount, 10),
          conference_number: formatPhoneNumber(customerPhone)
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log(`שיחות הותחלו:`, data);
        toast({
          title: "שיחות הותחלו",
          description: "המערכת החלה בביצוע השיחות.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await checkCallStatus();
      } else {
        const errorData = await response.json();
        console.error(`נכשל בהתחלת השיחות:`, errorData);
        toast({
          title: "נכשל בהתחלת השיחות",
          description: errorData.error || "אירעה שגיאה בלתי צפויה.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        clearPreviousSessionData();
      }
    } catch (error) {
      console.error(`שגיאה בהתחלת השיחות:`, error);
      toast({
        title: "שגיאה",
        description: "נכשל בהתחברות לשרת. אנא נסה שוב.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      clearPreviousSessionData();
    }
  };

  const stopCalls = async () => {
    try {
      const response = await fetch('https://nexmo-heroku-python-server-7146b95f181d.herokuapp.com/stop-calls', {
        method: 'POST',
      });

      if (response.ok) {
        setIsCallActive(false);
        setCallStatus('הופסק');
        console.log('השיחות הופסקו ונותקו בהצלחה');
        toast({
          title: "השיחות הופסקו",
          description: "כל השיחות הפעילות הופסקו ונותקו.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('נכשל בהפסקת וניתוק השיחות');
        toast({
          title: "נכשל בהפסקת וניתוק השיחות",
          description: "אירעה שגיאה בניסיון להפסיק ולנתק את השיחות.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('שגיאה בהפסקת וניתוק השיחות:', error);
      toast({
        title: "שגיאה",
        description: "נכשל בהתחברות לשרת. אנא נסה שוב.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const checkCallStatus = async () => {
    try {
      const response = await fetch('https://nexmo-heroku-python-server-7146b95f181d.herokuapp.com/call-status');
      
      if (response.ok) {
        const data = await response.json();
        console.log("Received call status:", data);

        setCallsMade(data.call_attempts);
        setTotalCallsMade(data.total_calls_made);
        setIsCallActive(data.is_calling_active);
        
        const allCallsCompleted = Object.values(data.call_attempts).every(call => call.completed);
        const anyActiveCall = Object.values(data.call_attempts).some(call => !call.completed);

        if (data.answered_call) {
          const answeredContact = contacts.find(c => formatPhoneNumber(c.phone) === formatPhoneNumber(data.answered_call));
          if (answeredContact) {
            setAnsweredContact(answeredContact);
            setCallStatus(`נענה על ידי ${answeredContact.name}`);
            toast({
              title: "שיחה נענתה",
              description: `${answeredContact.name} ענה לשיחה.`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        }

        if (!data.is_calling_active && allCallsCompleted) {
          setCallStatus('הושלם');
          if (!data.answered_call) {
            toast({
              title: "כל השיחות הושלמו",
              description: "תהליך השיחות הסתיים ללא מענה.",
              status: "info",
              duration: 5000,
              isClosable: true,
            });
          }
        } else if (anyActiveCall || data.is_calling_active) {
          const activeCallsCount = Object.values(data.call_attempts).filter(call => !call.completed).length;
          setCallStatus(`בתהליך: ${activeCallsCount} מספרים פעילים`);
          setCurrentCallIndex(activeCallsCount);
        }
      }
    } catch (error) {
      console.error(`שגיאה בבדיקת סטטוס השיחה:`, error);
    }
  };
  
  useEffect(() => {
    let interval;
    if (isCallActive) {
      interval = setInterval(checkCallStatus, 2000);
    }
    return () => clearInterval(interval);
  }, [isCallActive]);

  return (
    <Box mt={4}>
      <VStack spacing={4}>
        <Input
          placeholder="מספר שיחות"
          value={callCount}
          onChange={(e) => setCallCount(e.target.value)}
        />
        <Input
          placeholder="מספר טלפון של הלקוח"
          value={customerPhone}
          onChange={(e) => setCustomerPhone(e.target.value)}
        />
        <HStack>
          <Button colorScheme="teal" onClick={startCalls} isDisabled={isCallActive}>
            התחל שיחות
          </Button>
          <Button colorScheme="red" onClick={stopCalls} isDisabled={!isCallActive}>
            הפסק שיחות
          </Button>
          <Button onClick={clearPreviousSessionData}>
            נקה
          </Button>
        </HStack>
        <Text>סטטוס שיחה: {callStatus}</Text>
        <Text>שיחה נוכחית: {currentCallIndex}/{selectedContacts.length}</Text>
        {answeredContact && (
          <Text fontWeight="bold" color="green.500">
            הלקוח {answeredContact.name} ({displayPhoneNumber(answeredContact.phone)}) ענה לשיחה.
          </Text>
        )}
        {Object.entries(callsMade).map(([phone, callInfo]) => {
          const formattedPhone = formatPhoneNumber(phone);
          const contact = contacts.find(c => formatPhoneNumber(c.phone) === formattedPhone);
          const isAnswered = answeredContact && formatPhoneNumber(answeredContact.phone) === formattedPhone;
          return contact ? (
            <Box
              key={phone}
              p={2}
              bg={isAnswered ? "green.100" : "transparent"}
              borderRadius="md"
              borderWidth={1}
              borderColor={isAnswered ? "green.500" : "gray.200"}
            >
              <Text color={isAnswered ? "green.700" : "inherit"} fontWeight={isAnswered ? "bold" : "normal"}>
                {contact.name} - {displayPhoneNumber(contact.phone)}: שיחה {callInfo.attempts} מתוך {callInfo.max_attempts}
                {isAnswered && " (ענה)"}
                {callInfo.completed && !isAnswered && " (הושלם)"}
                {!callInfo.completed && ` (${callInfo.status})`}
              </Text>
            </Box>
          ) : null;
        })}
      </VStack>
    </Box>
  );
};

export default CallSection;