// src/components/Contacts.js
import React, { useState, useEffect } from 'react';
import {
  Box, Input, Button, List, ListItem, Text, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Checkbox, Flex, Spacer, VStack, HStack,
} from '@chakra-ui/react';
import { db, auth } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { DeleteIcon, EditIcon, PhoneIcon } from '@chakra-ui/icons';

const formatPhoneNumber = (phone) => {
  let cleanPhone = phone.replace(/[^\d+]/g, '');
  if (cleanPhone.startsWith('+972')) {
    return cleanPhone;
  }
  if (cleanPhone.startsWith('972')) {
    return '+' + cleanPhone;
  }
  if (cleanPhone.startsWith('0')) {
    return '+972' + cleanPhone.slice(1);
  }
  return '+972' + cleanPhone;
};

const displayPhoneNumber = (phone) => {
  if (phone.startsWith('+972')) {
    return '0' + phone.slice(4);
  }
  return phone;
};

const Contacts = ({ onContactSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contacts, setContacts] = useState([]);
  const [newContactName, setNewContactName] = useState('');
  const [newContactPhone, setNewContactPhone] = useState('');
  const [search, setSearch] = useState('');
  const [editContact, setEditContact] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      const user = auth.currentUser;
      if (user) {
        const contactsCollection = collection(db, `users/${user.uid}/contacts`);
        const contactSnapshot = await getDocs(contactsCollection);
        const contactList = contactSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setContacts(contactList);
      }
    };
    fetchContacts();
  }, []);

  useEffect(() => {
    onContactSelect(selectedContacts);
  }, [selectedContacts, onContactSelect]);

  const addContact = async () => {
    const user = auth.currentUser;
    if (user && newContactName && newContactPhone) {
      const formattedPhone = formatPhoneNumber(newContactPhone);
      const docRef = await addDoc(collection(db, `users/${user.uid}/contacts`), { name: newContactName, phone: formattedPhone });
      setContacts([...contacts, { id: docRef.id, name: newContactName, phone: formattedPhone }]);
      setNewContactName('');
      setNewContactPhone('');
      onClose();
    }
  };

  const updateContact = async (id, name, phone) => {
    const user = auth.currentUser;
    if (user) {
      const formattedPhone = formatPhoneNumber(phone);
      const contactDoc = doc(db, `users/${user.uid}/contacts`, id);
      await updateDoc(contactDoc, { name, phone: formattedPhone });
      setContacts(contacts.map(contact => (contact.id === id ? { ...contact, name, phone: formattedPhone } : contact)));
      setEditContact(null);
    }
  };

  const deleteContact = async (id) => {
    const user = auth.currentUser;
    if (user) {
      await deleteDoc(doc(db, `users/${user.uid}/contacts`, id));
      setContacts(contacts.filter(contact => contact.id !== id));
      setSelectedContacts(selectedContacts.filter(contactId => contactId !== id));
    }
  };

  const toggleSelectContact = (id) => {
    if (selectedContacts.includes(id)) {
      setSelectedContacts(selectedContacts.filter(contactId => contactId !== id));
    } else {
      setSelectedContacts([...selectedContacts, id]);
    }
  };

  const selectAllContacts = () => {
    if (selectedContacts.length === contacts.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts.map(contact => contact.id));
    }
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="חפש אנשי קשר"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <HStack>
          <Button onClick={onOpen}>הוסף איש קשר</Button>
          <Button onClick={selectAllContacts}>
            {selectedContacts.length === contacts.length ? 'בטל בחירת הכל' : 'בחר הכל'}
          </Button>
        </HStack>
        <List spacing={3}>
          {contacts
            .filter(contact => contact.name.toLowerCase().includes(search.toLowerCase()) || contact.phone.includes(search))
            .map(contact => (
              <ListItem key={contact.id} borderWidth={1} borderRadius="md" p={2}>
                <Flex alignItems="center">
                  <Checkbox
                    isChecked={selectedContacts.includes(contact.id)}
                    onChange={() => toggleSelectContact(contact.id)}
                    mr={2}
                  />
                  {editContact === contact.id ? (
                    <Flex flex="1">
                      <Input
                        value={contact.name}
                        onChange={(e) =>
                          setContacts(
                            contacts.map(c => (c.id === contact.id ? { ...c, name: e.target.value } : c))
                          )
                        }
                      />
                      <Input
                        value={displayPhoneNumber(contact.phone)}
                        onChange={(e) =>
                          setContacts(
                            contacts.map(c => (c.id === contact.id ? { ...c, phone: formatPhoneNumber(e.target.value) } : c))
                          )
                        }
                        ml={4}
                      />
                      <Spacer />
                      <Button onClick={() => updateContact(contact.id, contact.name, contact.phone)}>שמור</Button>
                    </Flex>
                  ) : (
                    <Flex flex="1" alignItems="center">
                      <Text fontWeight="bold" mr={2}>{contact.name}</Text>
                      <Spacer />
                      <Text><PhoneIcon mr={2} />{displayPhoneNumber(contact.phone)}</Text>
                      <Spacer />
                      <Box>
                        <IconButton
                          icon={<EditIcon />}
                          onClick={() => setEditContact(contact.id)}
                          mr={2}
                          aria-label="Edit contact"
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => deleteContact(contact.id)}
                          aria-label="Delete contact"
                        />
                      </Box>
                    </Flex>
                  )}
                </Flex>
              </ListItem>
            ))}
        </List>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>הוסף איש קשר חדש</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="שם איש קשר"
              value={newContactName}
              onChange={(e) => setNewContactName(e.target.value)}
              mb={4}
            />
            <Input
              placeholder="טלפון איש קשר"
              value={newContactPhone}
              onChange={(e) => setNewContactPhone(e.target.value)}
              mb={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={addContact}>הוסף</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Contacts;