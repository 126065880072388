// src/App.js
import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Button, Center, Spinner, Text, HStack, useToast } from '@chakra-ui/react';
import Login from './components/Login';
import Contacts from './components/Contacts';
import CallSection from './components/CallSection';
import { auth } from './firebase';

const SERVER_URL = 'https://nexmo-heroku-python-server-7146b95f181d.herokuapp.com';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serverStatus, setServerStatus] = useState('לא ידוע');
  const toast = useToast();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    await auth.signOut();
    setIsAuthenticated(false);
  };

  const checkServerStatus = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/server-status`);
      const data = await response.json();
      setServerStatus(data.status === 'OK' ? 'תקין' : 'שגיאה');
      
      const uptime = Math.floor(data.uptime / 60 / 60); // המרה לשעות
      const lastCall = data.last_call_time ? new Date(data.last_call_time * 1000).toLocaleString() : 'אין מידע';
      
      toast({
        title: `סטטוס שרת: ${data.status === 'OK' ? 'תקין' : 'שגיאה'}`,
        description: `זמן פעילות: ${uptime} שעות\nשיחה אחרונה: ${lastCall}`,
        status: data.status === 'OK' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('שגיאה בבדיקת סטטוס השרת:', error);
      setServerStatus('שגיאה');
      toast({
        title: 'שגיאה בבדיקת סטטוס השרת',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    // בדיקת סטטוס שרת כל 5 דקות
    const intervalId = setInterval(checkServerStatus, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box p={4}>
      {isAuthenticated ? (
        <>
          <HStack justifyContent="space-between" mb={4} flexWrap="wrap">
            <Button onClick={handleLogout}>התנתק</Button>
          </HStack>
          <Heading mb={4} textAlign="center">מערכת שיחות</Heading>
          <VStack spacing={8} align="stretch">
            <Contacts onContactSelect={setSelectedContacts} />
            <CallSection selectedContacts={selectedContacts} />
          </VStack>
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </Box>
  );
};

export default App;