// src/theme.js
import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
});

const theme = extendTheme({
  direction: "rtl",
  breakpoints,
  fonts: {
    heading: "Alef, sans-serif",
    body: "Alef, sans-serif",
  },
});

export default theme;
