// src/components/Login.js
import React, { useState } from 'react';
import { Box, Input, Button, Heading, FormControl, FormLabel, InputGroup, InputRightElement, useToast, Spinner, Center } from '@chakra-ui/react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast({
        title: "התחברות הצליחה",
        description: "ברוכים הבאים!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "שגיאה",
        description: "שם משתמש או סיסמה לא נכונים",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error logging in: ", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box p={4}>
      <Heading mb={4}>התחברות</Heading>
      <form onSubmit={handleLogin}>
        <FormControl mb={4}>
          <FormLabel>אימייל</FormLabel>
          <Input
            type="email"
            placeholder="אימייל"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>סיסמה</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="סיסמה"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button type="submit" colorScheme="teal" isDisabled={loading}>
          התחבר
        </Button>
        {loading && (
          <Center mt={4}>
            <Spinner size="lg" />
          </Center>
        )}
      </form>
    </Box>
  );
};

export default Login;
